import ResultDisplayProps from "../types/interfaces/props/ResultDisplayProps";

export default function ResultDisplay(props: ResultDisplayProps) {

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        props.changePage();
    }

    if (props.result.length === 0) {
        return <div className="text-lg">Your wallet is not traceable!</div>
    } else {
        return <div className="text-lg">Your wallet is traceable! <a href="#" onClick={handleClick} className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">View a list of traceable transactions</a></div>
    }
}
