import queryString from 'query-string';
import { useState } from 'react';
import ResultListDisplay from './components/page/ResultListDisplay';
import ResearchAnimation from './components/ResearchAnimation';
import ResultDisplay from './components/ResultDisplay';
import ResearchStatus from './types/enums/ResearchStatus';
import SearchAddressFormElement from './types/interfaces/forms/SearchAddressFormElement';
import getWalletTransactions from './utils/RequestUtils';

function App() {
    const [researchStatus, setResearchStatus] = useState(ResearchStatus.NOT_FILLED);
    const [result, setResult] = useState<null | any[]>(null);
    const [resultPage, setResultPage] = useState(false);

    if (resultPage) {
        return <ResultListDisplay changePage={() => setResultPage(false)} result={result} />
    }

    const parsed = queryString.parse(window.location.search);
    if (!parsed.secret) {
        return <div>Please specify your user key. contact@clarensia.com for more info</div>;
    }

    async function handleSubmit(e: React.FormEvent<SearchAddressFormElement>) {
        e.preventDefault();
        setResearchStatus(ResearchStatus.SEARCHING);
        const target = e.currentTarget.elements.address.value;
        const result = await getWalletTransactions(parsed.secret as string, target);
        setResult(result);
        setResearchStatus(ResearchStatus.DONE);
    }

    let researchText = null;

    if (researchStatus === ResearchStatus.SEARCHING) {
        researchText = <ResearchAnimation isDone={false} />;
    } else if (researchStatus === ResearchStatus.DONE) {
        researchText = <ResearchAnimation isDone={true} />;
    }

    let resultDisplay = null;
    if (result !== null) {
        resultDisplay = <ResultDisplay result={result} changePage={() => setResultPage(true)} />;
    }

    return (
        <div className="grid h-screen place-items-center">
            <div className="w-full max-w-2xl bg-white border border-gray-200 rounded-lg shadow-md">
                <h2 className="text-2xl mt-5 text-center">Trace an ethereum address</h2>
                <h4 className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-center mx-10 mt-1">Provide use with an ethereum address and we will tell you if this address is traceable or not.</h4>
                <form className="mt-6 mx-8" onSubmit={handleSubmit}>
                    <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        </div>
                        <input type="search" id="default-search" name="address" className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0x85D365293c6EBd3B2FFD0913439c8a37071362A1" required />
                        <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                    </div>
                </form>
                <div className="pl-8 pt-4">
                    {researchText}
                </div>
                <div className='pt-3 text-center'>
                    {resultDisplay}
                </div>
                <div className="text-center pt-4 pb-1 text-sm text-slate-500 italic">Blocktracking version 0.0.1 made by <a href="https://www.clarensia.com/" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Clarensia</a></div>
            </div>
        </div>
    )
}

export default App;
