import TableRowProps from "../../types/interfaces/props/TableRowProps";
import TableCell from "./TableCell";

function formatTime(time: Date) {
    return time.toLocaleString("fr-FR");
}

export default function TableRow(props: TableRowProps) {
    let color = "bg-gray-100";
    if (props.keyIndex % 2 === 0) {
        color = "bg-white";
    }
    const result = props.result;
    return <tr key={props.keyIndex} className={color + " border-b"}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{formatTime(new Date(result.metadata.blockTimestamp))}</td>
        <td className="text-sm px-6 py-4 whitespace-nowrap">
            <a href={"https://etherscan.io/tx/" + result.hash} target="_blank" rel="noreferrer" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">{result.hash}</a>
        </td>
        <TableCell text={result.asset ? result.asset : "Unknown"} />
        <TableCell text={result.value} />
        <TableCell text={result.from} />
        <TableCell text={result.to} />
        <td className="text-sm px-6 py-4 whitespace-nowrap">
            <img src={process.env.PUBLIC_URL + "/img/binance-logo.jpg"} />
        </td>
    </tr>
}
