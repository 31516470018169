export default async function getWalletTransactions(api_key: string, wallet: string): Promise<any[]> {
    const link = "https://blocktracking-back.clarensia.workers.dev/?q=" + api_key + "&target=" + wallet;
    const request = await fetch(link, {
        "method": "GET",
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    });
    return await request.json();
}
