import { useEffect, useState } from "react";
import ResearchAnimationProps from "../types/interfaces/props/ResearchAnimationProps";
import randInt from "../utils/Random";
import sleep from "../utils/Sleep";
import FoundElement from "./FoundElement";
import SearchingElement from "./SearchingElement";

// We put it outside because it should be the same regardless of props.
const transactions_amount = randInt(1836999864, 1999999999);

export default function ResearchAnimation(props: ResearchAnimationProps & React.HTMLAttributes<HTMLDivElement>) {
    const [currentState, setCurrentState] = useState(1);

    useEffect(() => {
        (async () => {
            await sleep(randInt(1000, 3000));
            setCurrentState(2);
            await sleep(randInt(3000, 6000));
            setCurrentState(3);
            // Next state will be set by parent component
        })();
    }, []);

    if (props.isDone) {
        return <div>
            <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Tracing your wallet:</h2>
            <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
                <FoundElement key="1" text="Searching for binance wallets: Found 12 binance wallets." />
                <FoundElement key="2" text={"Looking for transactions: found " + transactions_amount.toString() + " transactions"} />
                <FoundElement key="3" text={`Fetching all ${transactions_amount} transactions: DONE`} />
            </ul>
        </div>
    } else {
        let elements = [];
        if (currentState == 1) {
            elements.push(<SearchingElement key="1" text="Searching for binance wallets..." />);
        } else {
            elements.push(<FoundElement key="1" text="Searching for binance wallets: Found 12 binance wallets." />);
            if (currentState == 2) {
                elements.push(<SearchingElement key="2" text="Looking for transactions..." />);
            } else {
                elements.push(<FoundElement key="2" text={"Looking for transactions: found " + transactions_amount.toString() + " transactions"} />);
                if (currentState == 3) {
                    elements.push(<SearchingElement key="3" text={`Fetching all ${transactions_amount} transactions...`} />);
                }
            }
        }

        return <div>
            <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Tracing your wallet:</h2>
            <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
                {elements}
            </ul>
        </div>
    }
}
