import ResultListDisplayProps from "../../types/interfaces/props/ResultListDisplayProps";
import TableHeader from "../table/TableHeader";
import TableRow from "../table/TableRow";

export default function ResultListDisplay(props: ResultListDisplayProps) {
    let key = 0;
    return <div className="flex flex-col h-screen">
        <div className="flex-grow overflow-auto">
            <table className="relative w-full">
                <thead className="bg-white border-b sticky top-0">
                    <tr>
                        <TableHeader text="Time"></TableHeader>
                        <TableHeader text="Transaction Hash"></TableHeader>
                        <TableHeader text="Asset"></TableHeader>
                        <TableHeader text="Transaction Amount"></TableHeader>
                        <TableHeader text="From"></TableHeader>
                        <TableHeader text="To"></TableHeader>
                        <TableHeader text="Platform"></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {props.result.map((r: any) => {
                        key += 1;
                        return <TableRow keyIndex={key} result={r} />
                    })}
                </tbody>
            </table>
        </div>
    </div>

}